import React from 'react'
import { AboutPage } from '@pages/components'
import { Container } from '@components'
import './about.scss'

interface AboutProps {}

const About: React.FC<AboutProps> = () => {
  return (
    <section className={'about'}>
      <Container>
        <div className={'about-card'}>
          <AboutPage.FirstCard />
          <AboutPage.SecondCard />
        </div>
      </Container>
      <AboutPage.TourDayGuide />
    </section>
  )
}
export default About
